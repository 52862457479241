<template>
  <section class="wrapper">
    <row>
      <column xs="12">
        <article
          v-for="article in $store.state.articleList"
          :key="article.id">
          <Teaser
            :grid="[3,9]"
            :teaser-element="article"
            image-size="small"
            teaser-type="sponsorContent"
            show-lead-text>
            <template v-slot:header>
              <div
                v-if="article.contentOwner"
                class="content-owner ff-arial fs-sm fw-bold tt-uppercase d-block mb-1">{{ getContentOwner(article) }}</div>
            </template>
            <template v-slot:footer>
              <TeaserDetail
                :date="article.publishedAt"
                style-class="published-date"
                show-publish-prefix/>
            </template>
          </Teaser>
        </article>
      </column>
      <column
        xs="12">
        <Pagination
          :offset="$store.state.offset"
          :total="$store.state.total"
          :size="$store.state.size"/>
      </column>
    </row>
  </section>
</template>
<script>
import { Page, Pagination } from 'global-components';

export default {
  name: 'sponsor-edition',
  dataResolver: 'sponsorArticleListPage',
  components: {
    Pagination
  },
  extends: Page,
  methods: {
    getContentOwner(article) {
      const prefix = this.__('content.from');
      return article.contentOwner ? `${prefix} ${article.contentOwner}` : 'NHST Global Publication';
    }
  }
};
</script>
